window.$ = $;
window.jQuery = $;

import 'intersection-observer'
import Swipe from 'swipejs'
import './menu'
import './page-two-thoughts-in-mind'
import 'add-to-calendar-button';
import './_lazy-image';

$(document).ready(function() {
// Add js-class to body element
  var body = document.querySelector("body");
  addClass(body, "js");

// Setup slider and prev/next-buttons
  var sliders = document.querySelectorAll(".media-wrap.has-slides");
  Array.prototype.forEach.call(sliders, function (el, i) {
    var animate = el.getAttribute('data-animate');
    var autoSpeed = el.getAttribute('data-auto-speed');
    var navigation = el.querySelector('.slider-navigation');
    var navChildren = navigation ? navigation.querySelectorAll('div.nav-link') : [];
    var speed = 500;

    Window["slider-" + i] = new Swipe(el, {
      startSlide: 0,
      draggable: true,
      continuous: true,
      disableScroll: false,
      auto: animate ? (parseInt(autoSpeed) || 4000) : 0,
      speed: speed,
      callback: function (index) {
        if (navigation == null) {
          return;
        }

        Array.prototype.forEach.call(navChildren, function (child) {
          child.classList.remove('active');
        });
        if(navChildren.length > 0) {
          navChildren[index].classList.add('active');
        }
      }
    });

    Array.prototype.forEach.call(navChildren, function (child, index) {
      child.addEventListener('click', function () {
        Window["slider-" + i].slide(index, speed);
      });
    });
  });

  var slider_prev = document.querySelectorAll(".media-wrap button.slider-prev");
  Array.prototype.forEach.call(slider_prev, function (el, i) {
    el.addEventListener("click", function () {
      Window["slider-" + i].prev();
    });
  });

  var slider_next = document.querySelectorAll(".media-wrap button.slider-next");
  Array.prototype.forEach.call(slider_next, function (el, i) {
    el.addEventListener("click", function () {
      Window["slider-" + i].next();
    });
  });

  let sliderPoints = document.querySelectorAll('.media-wrap .slider-navigation .slide-info');
  if(sliderPoints && sliderPoints.length > 0) {
    sliderPoints.forEach((point) => {
      point.addEventListener('mouseenter', (e) => {
        sliderPoints.forEach((item) => {
          item.classList.add('fade-out');
        });
        point.classList.remove('fade-out');
      });
      point.addEventListener('mouseleave', (e) => {
        sliderPoints.forEach((item) => {
          item.classList.remove('fade-out');
        });        
        //point.classList.add('fade-out');
      });
    });
  }


// Projects
  function projectClickHandler(el, parent, selector) {
    el.addEventListener("click", function () {
      var child = parent.querySelector(selector);

      if (child.classList.contains('active')) {
        child.classList.remove('active');
        el.classList.remove('active');
      } else {
        child.classList.add('active');
        el.classList.add('active');
      }
    });
  }

  var projectGroups = document.querySelectorAll(".section__projects_group");
  Array.prototype.forEach.call(projectGroups, function (pg, i) {
    var groups = pg.querySelectorAll('h3');
    Array.prototype.forEach.call(groups, function (group, i) {
      projectClickHandler(group, pg, 'ul');
    });

    var projects = pg.querySelectorAll('ul li');
    Array.prototype.forEach.call(projects, function (project, i) {
      var headers = project.querySelectorAll('h4');
      Array.prototype.forEach.call(headers, function (p, i) {
        projectClickHandler(p, project, '.project-description');
      });
    });
  });

  /* document.getElementById('size-change').addEventListener('click', function (e) {
    if (e.preventDefault) {
      e.preventDefault();
    }
  }); */

// Toggle team bios
  $('.person a.more').click(function () {
    var $this = $(this);
    var less = $this.next();
    var bio = $this.next().next();
    bio.show(500);
    less.click(function () {
      bio.hide(500);
      $this.css('display', 'inline');
      less.css('display', 'none');
    })
    $this.css('display', 'none');
    less.css('display', 'inline');
  })

// Click to play video
  var videos = document.querySelectorAll(".media-wrap video");
  Array.prototype.forEach.call(videos, function (el, i) {
    el.addEventListener("click", function () {
      if (this.paused === true) {
        this.play();
        this.setAttribute("controls", "true");
        this.nextElementSibling.style.display = "none";
      } else if (this.paused === false) {
        this.pause();
        this.removeAttribute("controls");
        this.nextElementSibling.style.display = "block";
      }
    });
  });

// Helper functions
  function toggleClass(el, className) {
    if (el.classList) {
      el.classList.toggle(className);
    } else {
      var classes = el.className.split(' ');
      var existingIndex = classes.indexOf(className);

      if (existingIndex >= 0)
        classes.splice(existingIndex, 1);
      else
        classes.push(className);

      el.className = classes.join(' ');
    }
  }

  function addClass(el, className) {
    if (el.classList)
      el.classList.add(className);
    else
      el.className += ' ' + className;
  }

  function hasClass(el, className) {
    if (el.classList)
      el.classList.contains(className);
    else
      new RegExp('(^| )' + className + '( |$)', 'gi').test(el.className);
  }

  var anim_duration = 200;

  $('.websearch').click(function () {
    var animation1, animation2;

    if ($(this).hasClass('open')) {
      $('.sicon').show();
      $('.close-x').hide();
      $('.websearchwrapper').slideUp(anim_duration);
    } else {
      $('.sicon').hide();
      $('.close-x').show();
      $('.websearchwrapper').slideDown(anim_duration);
      $('#websearch').focus();
    }
    $(this).toggleClass('open');
  });

  if($('body').hasClass('search')) {
    let $searchInput = $('header').find('.websearchwrapper form input.search-autocomplete');
    let $inputValue = $searchInput.val();
    $searchInput.val('');
    $searchInput.val($inputValue);
  }

  $('.closecircle').click(function () {
    $(this).hide(anim_duration);
    $('.timeline-info').hide(anim_duration);
  })

  $('g.circle').click(function () {
    var $this = $(this);

    var svg = $('.section__timeline .container svg');
    var top = -(svg.height() - ($this.position().top - $('.section__timeline .container').position().top));
    var ingressNumber = 1;
    var info = $('.timeline-info');
    $this.find('text').each(function () {
      var text = $(this).text();
      if ($(this).hasClass('date')) {
        if ($(this).hasClass('all') || $(this).hasClass(language)) {
          info.find('.date').html(text);
        }
      } else {
        if ($(this).hasClass(language)) {
          info.find('.ingress' + ingressNumber).html(text);
          ingressNumber++;
        }
      }
    });
    info.css('top', (top + 38) + 'px');
    info.show(anim_duration);

    var circle = $this.find('use');
    var x = circle.attr('x');
    var y = circle.attr('y');
    $('.closecircle').attr('transform', 'translate(' + x + ' ' + y + ')');
    $('.closecircle').show(anim_duration);

  });

  $('.section__timeline .timeline .' + language).show(0);
  $('.section__timeline .timeline .all').show(0);

// Publication tabs
  var $publications = $('.section__publications');
  $publications.each(function (index, publication) {
    var $publication = $(publication);
    var $tabs = $publication.find('.tabs');
    var $contentBlocks = $publication.find('.content > div');

    $tabs.find('a').click(function (e) {
      e.preventDefault();

      $tabs.find('li').removeClass('active');
      $contentBlocks.removeClass('active');

      var $parent = $(this).parent();
      var index = $parent.index();

      $parent.addClass('active');
      $($contentBlocks.get(index)).addClass('active');
    });

  });

  if($('.application-form').length > 0 && $('.application-form').data('lang') == 'no') {
    setTimeout(function() {
      $('.application-form .codedropz-upload-inner span').html('eller');
      $('.application-form .codedropz-upload-inner span').first().html('Dra og slipp filer her');
      $('.application-form .codedropz-upload-inner .cd-upload-btn').html('Bla gjennom filer');
      
    }, 100);
  }
});

import './_section-text.js';
import './_banner.js';
import './_link-shortcuts.js';
import './_scroll-to';
import './_reports-and-presentation.js';
import './_accordion.js';
import './_financial-calendar.js';
import './_publications.js';

import './_search-results.js';